import { createEventHandler } from '@wix/tpa-settings';
import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { IEvents, SETTINGS_EVENTS, TabState } from '../../constants/settings-events';
import { plansFixtureTranslated } from '../../fixtures';
import { createPlansApi, memoizePlansApi } from '../../services';
import { Analytics } from '../../services/analytics';
import { commonBIDataFromFlowAPI } from '../../services/commonBIData';
import { WarmupData } from '../../services/WarmupData';
import { PlanListInteractions } from '../../types/PlanListFedops';
import { isMultiPageApp } from '../../utils/isMultiPageApp';
import { Navigation } from '../PackagePicker/controller/Navigation';
import { SettingsReader } from '../PackagePicker/DefaultSettingsAdapter';
import { PlanListController } from './PlanListController';
import settingsParams from './settingsParams';

const createController: CreateControllerFn = async (params) => {
  const {
    flowAPI,
    controllerConfig: { setProps, wixCodeApi, appParams, config, compId },
  } = params;

  const defaultDemoPlans = plansFixtureTranslated(flowAPI.translations.t);

  const demoPlans = config.publicData?.APP?.demoData?.plans ?? defaultDemoPlans;

  flowAPI.bi?.updateDefaults(commonBIDataFromFlowAPI(flowAPI));
  const api = createPlansApi(flowAPI.httpClient);
  const componentEventHandler = createEventHandler<IEvents>(config.publicData.COMPONENT || {});
  const analytics = new Analytics(wixCodeApi.window);
  const settings = new SettingsReader(flowAPI.settings, settingsParams);
  const warmupData = new WarmupData(compId, wixCodeApi, flowAPI);
  const navigation = new Navigation(flowAPI, appParams.appDefinitionId, await isMultiPageApp(wixCodeApi, appParams));
  const pp = new PlanListController(
    setProps,
    memoizePlansApi(api),
    flowAPI,
    wixCodeApi,
    appParams,
    analytics,
    settings,
    demoPlans,
    warmupData,
    navigation,
  );

  return {
    async pageReady() {
      flowAPI.fedops.interactionStarted(PlanListInteractions.PageReady);
      flowAPI.panoramaClient?.transaction(PlanListInteractions.PageReady).start();

      setProps({ fitToContentHeight: true });

      componentEventHandler.on(SETTINGS_EVENTS.TAB_STATE, (tabState: TabState) => pp.setProps({ tabState }));
      componentEventHandler.on(SETTINGS_EVENTS.ARE_SETTINGS_OPENED, (areSettingsOpened: boolean) =>
        pp.setProps({ areSettingsOpened }),
      );
      componentEventHandler.onReset(() => pp.setProps({ tabState: TabState.REGULAR, areSettingsOpened: false }));

      flowAPI.fedops.interactionEnded(PlanListInteractions.PageReady);
      flowAPI.panoramaClient?.transaction(PlanListInteractions.PageReady).finish();
      await pp.initialize();
    },
    async updateConfig(_$w, newConfig) {
      componentEventHandler.notify(newConfig.publicData.COMPONENT || {});
      setProps(await pp.fetchAndOrderPlans());
    },
  };
};

export default createController;
